body {
    font: 400 16px 'Varela Round', sans-serif;
    /*user-select: none;*/
    overflow-x: hidden;
    /*overflow-y: hidden;*/
}

code {
    background-color: #f0f0f0; /* Hintergrundfarbe der Box */
    border: 1px solid #ccc; /* Rahmen der Box */
    border-radius: 10px; /* Abgerundete Ecken */
    padding: 20px; /* Innenabstand der Box */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Schatten der Box */
}

.mark {
    background-color: #FFFBBB; /* gelber Hintergrund */
    color: #000000; /* schwarzer Text */
    padding: 0.03em 0.1em; /* Rand um den Text */
    border-radius: 0.3em; /* abgerundete Ecken */
    box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.2); /* leichter Schatten */
    /*font-weight: bold; !* Text fett *!*/
    display: inline-block;
    white-space: nowrap;
}


*::-webkit-scrollbar {
    display: none;
}


hr {
    color: #DADBE1;
    height: 1.5px;
}

option {
    display: inline-block;
}

button {
    user-select: none;
}

.approved-bg {
    background-color: rgba(92, 184, 92, 0.53);
}

.denied-bg {
    background-color: rgba(217, 83, 79, 0.49)
}

.type-in-card-answer-container {
    display: grid;
    place-items: center;
    grid-auto-flow: row;
}


.type-answer-input {
    font-size: 20px;
    color: #1C2025;
    background-image: linear-gradient(to right top, rgba(240, 247, 255, 0.3) 40%, rgba(243, 246, 249, 0.2) 100%);
    border-radius: 7px;
    border: 1px solid #E4EAF2;
    padding: 6px 20px;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 150ms, 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms, 0ms;
    transition-property: border, box-shadow;
}

.type-answer-input:hover {
    border: 1px solid #21658F;
    background-image: none;
}

.type-answer-input:focus {
    outline: none;
}


.head-line-gradient {
    background: #FFFDF7;
    background: linear-gradient(to top left, #FFFDF7 0%, #404040 25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-image-size {
    max-height: 400px;
}

.katex-display, .katex-display > .katex, .katex-display > .katex > .katex-html {
    display: inline !important;
    /*/ / make it inline, so it can render with your text even after ` displayMode: true `*/
}

.katex {
    text-align: center !important;
}

.katex-display {
    text-align: left !important;
}

.katex-display > .katex {
    text-align: left !important;
}




